import { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Modal, ModalBody, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { BsFileEarmarkPdfFill, BsSearch } from 'react-icons/bs'
import '../../../css/masterDetail.css'
import '../../../css/generalStyles.css'
import DSHeineken from '../Services/Heineken.Services'
import FileManagerDS from '../../../Services/Utils/FileManager.Services'
import DataTable from 'react-data-table-component'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import DTOProgCruce from '../DTO/DTOProgCruce'
import { IconContext } from 'react-icons'
import { FaCircleCheck, FaCircleXmark, FaFileExcel } from 'react-icons/fa6'
import xlsx, { IJsonSheet } from 'json-as-xlsx'
import FileManager from '../../Utils/FileManager/FileManager'
import FileManagerServices from '../../../Services/Utils/FileManager.Services'

export interface IRptProgramacionCrucesProps {}

export default function RptProgramacionCruces(
  props: IRptProgramacionCrucesProps
) {
  const [Data, setData] = useState<DTOProgCruce[]>([])
  const [DataOriginal, setDataOriginal] = useState<DTOProgCruce[]>([])
  const [Inicio, setInicio] = useState(currentDate(0))
  const [Fin, setFin] = useState(currentDate(0))
  const [filtro, setFiltro] = useState('')
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [msgColor, setMsgColor] = useState('primary')
  const [showFileReplacementModal, setShowFileReplacementModal] = useState(false)
  const [FileId, setFileId] = useState(0)
  const [ProcessId, setProcessId] = useState(0)
  const [UserId, setUserId] = useState(() => {
    const stickyValue = window.localStorage.getItem('UserId')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const [Perfil, setPerfil] = useState(() => {
    const stickyValue = window.localStorage.getItem('Perfil')
    return stickyValue !== null ? JSON.parse(stickyValue) : 0
  })
  const colData = [
    {
      name: 'id',
      width: '100px',
      selector: (row: DTOProgCruce) => row.id,
      sortable: true,
    },
    {
      name: 'Prefile',
      center: true,
      width: '80px',
      cell: (row: DTOProgCruce) => (
        <OverlayTrigger
          placement='top'
          overlay={ row.fechaPrefile ? <Tooltip id="button-tooltip-2">{row.fechaPrefile}</Tooltip> : <br style={{display:'none'}}/>}
        >
        <div style={
          row.fechaPrefile === '' ? {
            pointerEvents:'none',
            opacity: 0.5
          } : {
            
          }
        }>
          <IconContext.Provider value={{ color: 'red', size: '17px' }}>
            <BsFileEarmarkPdfFill 
              onClick={() => {
                Perfil === "Administrador" || Perfil === "GEMCO" ?
                openFileReplacementModal(row.id, 32) :
                GetPrefile(row.factura, row.factura2)
              }}
              style={{ cursor: 'pointer' }}
            />
          </IconContext.Provider>
        </div>
        </OverlayTrigger>
      ),
    },
    {
      name: 'Doda',
      center: true,
      width: '80px',
      cell: (row: DTOProgCruce) => (
        <OverlayTrigger
          placement='top'
          overlay={ row.fechaDoda ? <Tooltip id="button-tooltip-2">{row.fechaDoda}</Tooltip> : <br style={{display:'none'}}/>}
        >
          <div 
            style={
              row.fechaDoda === '' ? {
                pointerEvents:'none',
                opacity: 0.5
              } : {
                
              }
            }
          >
            <IconContext.Provider value={{ color: 'red', size: '17px' }}>
              <BsFileEarmarkPdfFill 
              onClick={() => {
                Perfil === "Administrador" || Perfil === "GEMCO" ?
                openFileReplacementModal(row.id, 34) :
                GetDoda(row.id)
              }}
              style={{ cursor: 'pointer' }}/>
            </IconContext.Provider>
          </div>
        </OverlayTrigger>
      ),
    },
    {
      name: 'BL',
      center: true,
      width: '60px',
      cell: (row: DTOProgCruce) => (
        <div
          onClick={() => {
            ShowBL2PDF(row.id)
          }}
          style={{ cursor: 'pointer' }}
        >
          <IconContext.Provider value={{ color: 'red', size: '17px' }}>
            <BsFileEarmarkPdfFill />
          </IconContext.Provider>
        </div>
      ),
    },
    {
      name: 'Folio Carta Porte',
      width: '300px',
      //selector: (row: DTOProgCruce) => row.folioCartaPorte,
      cell: (row: DTOProgCruce) => (
        <OverlayTrigger
          placement='top'
          overlay={ row.fechaHoraFCP !== '' ? <Tooltip id="button-tooltip-2">{row.fechaHoraFCP}</Tooltip> : <br style={{display:'none'}}/>}
        >
          <span style={{ cursor: 'pointer' }}>{row.folioCartaPorte}</span>
        </OverlayTrigger>
      ),
      sortable: true,
    },
    {
      name: 'SCAC',
      width: '100px',
      selector: (row: DTOProgCruce) => row.scac,
      sortable: true,
    },
    {
      name: 'CAAT',
      width: '100px',
      selector: (row: DTOProgCruce) => row.caat,
      sortable: true,
    },
    {
      name: 'Transportista Americano',
      width: '200px',
      cell: (row: DTOProgCruce) => (
        <div
          style={{
            alignItems:'center',
            textAlign: 'center',
            cursor: 'pointer',
            width:'100%'
          }}
        >
          <IconContext.Provider value={{ color: row.transporteAmericano ? 'green' : 'red', size: '18px' }}>
            {row.transporteAmericano ? <FaCircleCheck/> : <FaCircleXmark/>}
          </IconContext.Provider>
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Trafico',
      width: '100px',
      selector: (row: DTOProgCruce) => row.trafico,
      sortable: true,
    },
    {
      name: 'Remesa',
      width: '100px',
      selector: (row: DTOProgCruce) => row.remesa,
      sortable: true,
    },
    {
      name: 'Caja',
      width: '180px',
      selector: (row: DTOProgCruce) => row.caja,
      sortable: true,
    },
    {
      name: 'Fecha Estimada Cruce',
      width: '200px',
      selector: (row: DTOProgCruce) => row.fechaEstimadaCruce,
      sortable: true,
    },
    {
      name: 'Fecha Cita',
      width: '150px',
      selector: (row: DTOProgCruce) => row.fechaCita,
      sortable: true,
    },
    {
      name: 'Transportista',
      width: '300px',
      selector: (row: DTOProgCruce) => row.transportista,
      sortable: true,
    },
    {
      name: 'Proveedor',
      width: '200px',
      selector: (row: DTOProgCruce) => row.proveedor,
      sortable: true,
    },
    {
      name: 'Factura',
      selector: (row: DTOProgCruce) => row.factura,
      sortable: true,
    },
    {
      name: 'Factura2',
      selector: (row: DTOProgCruce) => row.factura2,
      sortable: true,
    },
    {
      name: 'Pedido',
      selector: (row: DTOProgCruce) => row.pedido,
      width:'150px',
      sortable: true,
    },
    {
      name: 'Pedido SAP',
      width: '120px',
      selector: (row: DTOProgCruce) => row.pedidoSAP,
      sortable: true,
    },
    {
      name: 'Delivery',
      width: '120px',
      selector: (row: DTOProgCruce) => row.delivery,
      sortable: true,
    },
    {
      name: 'Shipment',
      selector: (row: DTOProgCruce) => row.shipment,
      sortable: true,
    },
    {
      name: 'Destino',
      width: '250px',
      selector: (row: DTOProgCruce) => row.destino,
      sortable: true,
    },
    {
      name: 'Fecha',
      width: '100px',
      selector: (row: DTOProgCruce) => row.fecha.substring(0, 10),
      sortable: true,
    },
    {
      name: 'Aduana',
      selector: (row: DTOProgCruce) => row.aduana,
      sortable: true,
    },
    {
      name: 'Patente',
      selector: (row: DTOProgCruce) => row.patente,
      sortable: true,
    },
    {
      name: 'Bultos',
      selector: (row: DTOProgCruce) => row.bultos,
      sortable: true,
    },
    {
      name: 'Urgente',
      selector: (row: DTOProgCruce) => row.urgente,
      sortable: true,
    },
    {
      name: 'Estatus',
      selector: (row: DTOProgCruce) => row.estatus,
      sortable: true,
    },
    {
      name: 'Fecha impr.',
      selector: (row: DTOProgCruce) => row.fechaImpresion,
      sortable: true,
    },
    {
      name: 'Dias trans',
      right: true,
      selector: (row: DTOProgCruce) => row.diasTranscurridos,
      sortable: true,
    },
  ]

  const ShowBL2PDF = (id: number) => {
    FileManagerServices.getBL(id)
          .then((response: any) => {
            if (response.status === 200) {
              const blob = new Blob([response.data], { type: 'application/pdf' })
              const url = window.URL.createObjectURL(blob)
              window.open(url)
            }
          })
          .catch((e: Error) => {
            setHeader('Error')
            setMsg('Ocurrió un error al generar el BL')
            setShowMsg(true)
            return
          })
    /* const newWindow = window.open(
      process.env.REACT_APP_SERVICES +
        'heineken/services/BOL2PDF' +
        '?id=' +
        id,
      '_blank',
      'noopener,noreferrer'
    )
    if (newWindow) newWindow.opener = null */
  }

  const GetPrefile = (Factura: string, Factura2: string) => {
    FileManagerDS.getPrefile(Factura, Factura2.substring(2))
      .then((response: any) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(blob)
          window.open(url)
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Prefile no existe')
        setShowMsg(true)
        return
      })
  }

  function currentDate(days: number): string {
    var today = new Date()
    today.setDate(today.getDate() + days)
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0')
    var yyyy = today.getFullYear()
    return yyyy + '-' + mm + '-' + dd
  }

  const loadReport = (Inicio: string, Fin: string) => {
    DSHeineken.GetProgramacionCruces()
      .then((response) => {
        console.log(response.data)
        setData(response.data)
        setDataOriginal(response.data)
      })
      .catch((e: Error) => {
        alert('Ocurrio un error' + e.message.toString())
      })
  }

  useEffect(() => {
    loadReport(currentDate(0), currentDate(0))
  }, [])

  const filtraReporte = (e: any) => {
    setFiltro(e.target.value)
    if (e.target.value.length > 0) {
      setData(
        Data.filter(function (row) {
          return (
            row.fecha
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.factura
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.pedido
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.proveedor
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.destino
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.pedidoSAP
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.caja
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.transportista
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.aduana
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.patente
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.pedimento
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) ||
            row.trafico
              .toLowerCase()
              .includes(e.target.value.toLocaleLowerCase()) 
          )
        })
      )
    } else {
      setData(DataOriginal)
    }
  }

  function exportExcel(jsonData: DTOProgCruce[], fileName: string): void {
    let Heading = [
      [
        'Proveedor',
        'Factura',
        'Pedido',
        'Pedido SAP',
        'Delivery',
        'Shipment',
        'Destino',
        'Fecha de factura',
        'Aduana',
        'Patente',
        'Pedimento',
        'Caja',
        'Bultos',
      ],
    ]
    const dataOnly = jsonData.map(
      ({
        trafico,
        proveedor,
        pedido,
        pedidoSAP,
        delivery,
        shipment,
        destino,
        fecha,
        aduana,
        patente,
        pedimento,
        caja,
        bultos,
        //fechaEnvio,
        fechaPrefile,
        fechaDoda,
        //urgente,
        //fechaImpresion,
        diasTranscurridos
      }) => {
        return {
          trafico,
          proveedor,
          pedido,
          pedidoSAP,
          delivery,
          shipment,
          destino,
          fecha,
          aduana,
          patente,
          pedimento,
          caja,
          bultos,
          //fechaEnvio,
          fechaPrefile,
          fechaDoda,
          //urgente,
          //fechaImpresion,
          diasTranscurridos
        }
      }
    )
    let data: IJsonSheet[] = [
      {
        sheet: 'Programaciones de Cruce',
        columns: [
          { label: 'Trafico', value: 'trafico' },
          { label: 'Proveedor', value: 'proveedor' },
          { label: 'Pedido', value: 'pedido' },
          { label: 'Pedido SAP', value: 'pedidoSAP' },
          { label: 'Delivery', value: 'delivery' },
          { label: 'Shipment', value: 'shipment' },
          { label: 'Destino', value: 'destino' },
          {
            label: 'Fecha de Factura',
            value: (row: any) =>
              row.fecha
                ? row.fecha.substring(0, 10)
                : '',
          },
          { label: 'Aduana', value: 'aduana' },
          { label: 'Patente', value: 'patente' },
          { label: 'Pedimento', value: 'pedimento' },
          { label: 'Caja', value: 'caja' },
          { label: 'Bultos', value: 'bultos' },
          {
            label: 'Fecha Prefile',
            value: (row: any) =>
              row.fechaPrefile
                ? row.fechaPrefile
                : '',
          },
          {
            label: 'Fecha DODA',
            value: (row: any) =>
              row.fechaDoda
                ? row.fechaDoda
                : '',
          },
          { label: 'Dias en Transito', value: 'diasTranscurridos' },
        ],
        content: dataOnly,
      },
    ]
    let settings = {
      fileName,
      extraLength: 3,
      writeOptions: {},
      RTL: false,
    }
    xlsx(data, settings)
  }

  const downloadExcel = () => {
    exportExcel(Data, 'Reporte de Facturas')
  }

  const GetDoda = (id: number) => {
    FileManagerDS.getFileContent(id, 34)
    .then((response: any) => {
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        window.open(url)
      }
    })
    .catch((e: Error) => {
      setHeader('Error')
      setMsg('Doda no existe')
      setShowMsg(true)
      return
    })
  }

  const openFileReplacementModal = (idFile: number, proceso: number) => {
    setFileId(idFile)
    setProcessId(proceso)
    setShowFileReplacementModal(true)
  }

  const hideFileReplacementmodal = () => {
    setShowFileReplacementModal(false)
    loadReport(Inicio, Fin)
  }
  return (
    <div>
      <Row style={{ paddingTop: '10px' }}>
        <Col xs={12}></Col>
      </Row>
      <Card>
        <Card.Body>
          <Row>
            <Col xs={3}>
              <Card.Title>Programacion de cruces</Card.Title>
            </Col>
            <Col xs={5}>
              <Form.Control
                type='text'
                size='sm'
                placeholder='Search...'
                onChange={(e) => {
                  filtraReporte(e)
                }}
              />
            </Col>
            <Col xs={4}>
              <Button variant='primary' onClick={() => {loadReport(Inicio, Fin)}} style={{marginRight:'10px'}}> <BsSearch />&nbsp;Actualizar </Button>
              <Button variant='success' onClick={() => {downloadExcel()}}> <FaFileExcel/>&nbsp;Excel </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row style={{ paddingTop: '10px' }}>
        <Col xs={12}>
          <DataTable
            noHeader
            defaultSortFieldId={'id'}
            defaultSortAsc={true}
            pagination
            paginationPerPage={15}
            striped={true}
            dense={true}
            highlightOnHover
            columns={colData}
            data={Data.filter(function (row) {
              return row
            })}
          />
        </Col>
      </Row>
      <Modal
        size='lg'
        show={showFileReplacementModal}
        onHide={() => hideFileReplacementmodal()}
      >
        <ModalBody>
          <FileManager
            IDProcess={ProcessId}
            IDUser={UserId} 
            IdFile={FileId} 
            FileName={''} 
            Leyenda={''} 
            canDelete={true} 
            FileType={['pdf', 'PDF']}
            width={700} 
            height={100} 
            onAppendFM={(idFile: number) => {}}
            onDeleteFM={(IDMaster: number, Proceso: number) => {
              setTimeout(() => {
                hideFileReplacementmodal()
              }, 5000)
            }}
          />
        </ModalBody>
      </Modal>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}
